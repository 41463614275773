<script>
import { mapActions } from "vuex";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: {
    MainCheckbox,
    MainButton,
  },

  data() {
    return {
      isLoading: false,
      isAccepted: false,

      agreementUrl: "",
    };
  },

  watch: {
    isAccepted() {
      if (this.isAccepted === true) {
        this.accept("dd_agreement");
      }
    },
  },

  methods: {
    ...mapActions({
      acceptAgreement: "account/acceptAgreement",
      loadDocuments: "loadDocuments",
    }),

    accept(agreementType) {
      this.isLoading = true;

      this.acceptAgreement({ agreementType: agreementType })
        .then(() => {
          this.$router.push("/");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    redirect() {
      location.href = "/";
    },
  },

  mounted() {
    this.loadDocuments({
      page: "offerta_double_diamond",
    }).then((response) => {
      this.agreementUrl = response.data.offerta_docs[0].url;
    });
  },
};
</script>

<template>
  <div class="agreement">
    <div class="agreement-block">
      <h2>Вы достигли квалификации Топ-лидер продаж</h2>

      <p>
        Для продолжения работы с кабинетом дистрибьютора необходимо ознакомиться
        и принять «Cоглашение о персональной ответственности дистрибьютора от
        квалификации Топ-лидер продаж».
      </p>
      <p>
        До момента принятия соглашения доступ в кабинет дистрибьютора, переводы
        входящие, между своими счетами и другим пользователям будут
        заблокированы.
      </p>
      <p>
        По всем интересующим вопросам обращайтесь в
        <a href="https://support.rc.today/sign-in?source=cd">техподдержку</a>.
      </p>

      <span class="agreement-block__separator"></span>

      <MainCheckbox
        v-model="isAccepted"
        class="agreement-block__checkbox"
        :href="agreementUrl"
        :title="'Я принимаю '"
        :link-text="'соглашение о персональной ответственности дистрибьютора от квалификации Топ-лидер продаж'"
      />

      <MainButton
        class="agreement-block__button"
        :title="'Вернуться к кабинету'"
        color="gold"
        :disabled="!isAccepted || isLoading"
        @click="redirect"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 48px;

  .agreement-block {
    padding: 48px;
    background: $light-primary;
    border: 1px solid $outline-light;
    border-radius: 8px;
    max-width: 680px;

    > h2 {
      @include title-3;
      color: $dark-primary;
      margin-bottom: 32px;
    }

    > p {
      @include text-2;
      color: $dark-primary;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      > a {
        text-decoration: none;
        color: $blue;
      }
    }

    &__separator {
      display: block;
      height: 1px;
      width: 100%;
      background-color: $outline-light;
      margin: 24px 0;
    }

    &__checkbox {
      margin-bottom: 32px;
    }

    & ::v-deep .main-checkbox span {
      max-width: 100% !important;
    }

    &__button {
      max-width: 240px;
    }
  }
}

@media (max-width: 768px) {
  .agreement {
    padding: 32px;

    .agreement-block {
      padding: 32px;
    }
  }
}

@media (max-width: 425px) {
  .agreement {
    padding: 24px;
  }
}

@media (max-width: 375px) {
  .agreement {
    padding: 16px;
  }
}
</style>
